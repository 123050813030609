import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=6ce8ba78&scoped=true&"
import script from "./AppHeader.vue?vue&type=script&lang=js&"
export * from "./AppHeader.vue?vue&type=script&lang=js&"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=6ce8ba78&lang=SCSS&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce8ba78",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsBar: require('/data/www/nvpa.org/nvpa/nuxt/components/news/Bar.vue').default})
